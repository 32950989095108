function About(){
    return (
        <>
            <h1> What do you need to know about Demetrio ?</h1>
            <p>
                I have a plethora of expirience in a vartiey of fields 
            </p>
        
        </>
    )
}
export default About;